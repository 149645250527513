<!-- 新增编辑版本管理 -->
<template>
  <div class="box-card" style="text-align: left;margin-top: 0;">
    <el-form :model="batchAddForm" :rules="batchAddFormRules" ref="batchAddFormRef" label-width="120px" class="form_box">
      <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item label="客户端：" prop="phone_client">
            <el-select popper-class="my-select" filterable v-model="batchAddForm.phone_client" placeholder="请选客户端">
              <el-option v-for="item in filterAppClient" :key="item.value" :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="版本号：" prop="version_release">
            <el-input v-model="batchAddForm.version_release" placeholder="请输入版本号" maxlength="30"></el-input>
          </el-form-item>
          <el-form-item label="推送时间：" prop="push_time">
            <el-date-picker v-model="batchAddForm.push_time" type="datetime" placeholder="选择日期时间"
              :default-time='defaultDate' :picker-options="{
                disabledDate(time) {
                  return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
                },
                selectableRange: startTimeRange
              }" value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm" @focus="setdefaultDate">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="系统：" prop="system_type">
            <el-select popper-class="my-select" filterable v-model="batchAddForm.system_type" placeholder="请选系统">
              <el-option v-for="item in systemList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="更新方式：" prop="update_mode">
            <el-select popper-class="my-select" filterable v-model="batchAddForm.update_mode" placeholder="请选更新方式">
              <el-option v-for="item in updateModeList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="附件：" prop="attachment_address">
            <el-upload class="logo-upload" action="''" :http-request="upload" :show-file-list="true" :file-list="fileList" :disabled="isUploading">
              <lbButton size="small" type="warning">
                <template v-if="isUploading">
                  <i class="el-icon-loading"></i>
                  上传中
                </template>
                <template v-else>
                  {{ batchAddForm.attachment_address ? '点击修改' : '点击上传' }}
                </template>
              </lbButton>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="版本说明：" prop="version_desc">
        <el-input type="textarea" v-model="batchAddForm.version_desc" maxlength="300" :rows="5" placeholder="请输入版本说明："
          @input="(e) => (batchAddForm.version_desc = validSpacing(e))"></el-input>
      </el-form-item>
    </el-form>
    <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm" v-preventReClick>保 存
    </lbButton>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { getVersionManagementDetail, uploadVersionFile, addVersionManagement, updateVersionManagement } from '@/api/maintenanceCenter'
export default {
  name: 'addVersionManagement',
  components: {},
  props: ['id'],
  data () {
    var validatePushTime = (rule, value, callback) => {
      let isPast = true
      const selectTime = this.$moment(this.batchAddForm.push_time).format('YYYY-MM-DD HH:mm:ss')
      const now = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      if (now > selectTime) {
        isPast = true
      } else {
        isPast = false
      }
      if (isPast) {
        callback(new Error('请选择之后的时间'))
      } else {
        if (this.batchAddForm.push_time) {
          callback()
        } else {
          callback(new Error('请选择推送时间'))
        }
      }
    }
    return {
      batchAddForm: {
        system_type: 1, // 系统类型 1=android，2=iOS
        version_release: '', // 发行版本号
        version_desc: '', // 版本说明
        phone_client: 4, // 客户端类型（枚举GroupPhoneClient）
        push_time: '', // 推行时间
        update_mode: 1, // 更新方式 1=提示更新，2=强制更新
        attachment_address: '', // 附件地址
        is_open: false // 是否启用
      },
      // 文件列表
      fileList: [],
      defaultDate: '',
      filterAppClient: [], // 筛选只有app的客户端
      isUploading: false, // 是否正在上传
      systemList: [
        { name: 'Android', value: 1 },
        { name: 'iOS', value: 2 }
      ],
      environmentList: [
        { name: '开发环境', value: 1 },
        { name: '测试环境', value: 2 },
        { name: '正式环境', value: 3 }
      ],
      updateModeList: [
        { name: '提醒更新', value: 1 },
        { name: '强制更新', value: 2 }
      ],

      batchAddFormRules: {
        version_release: [{ required: true, message: '请输入版本号', trigger: 'blur' },
          { pattern: /^[0-9]\d?(\.([0-9]?\d)){2}$/, message: '请输入正确版本号（0-99.0-99.0-99）', trigger: 'blur' }],
        version_desc: [{ required: true, message: '请输入版本说明', trigger: 'blur' }],
        push_time: [{ required: true, trigger: 'blur', validator: validatePushTime }],
        attachment_address: [{ required: true, message: '请上传附件', trigger: 'blur' }],
        system_type: [{ required: true, message: '请选择系统', trigger: 'change' }],
        phone_client: [{ required: true, message: '请选择客户端', trigger: 'change' }],
        update_mode: [{ required: true, message: '请选择更新方式', trigger: 'change' }]
      },
      //   pickerOptions: {
      //     disabledDate (time) {
      //       return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000
      //     },
      //     selectableRange: this.startTimeRange
      //   },
      startTimeRange: ''
    }
  },
  watch: {
    'batchAddForm.push_time': {
      deep: true,
      handler (newValue, oldValue) {
        if (newValue) {
          const newTime = this.$moment(newValue).format('YYYY-MM-DD HH:mm:ss').split(' ')[0]
          const oldTime = this.$moment(oldValue).format('YYYY-MM-DD HH:mm:ss').split(' ')[0]
          const nowDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
          const dt = nowDate.split(' ')
          let st = ''
          if (newTime === dt[0]) {
            // 如果是当天,选择的时间开始为此刻的时分秒
            st = dt[1]
            // 如果是当天（只有当日期改变时）,默认选中时间为当前时间
            // if (newTime !== oldTime) {
            //   this.myTime = nowDate
            // }
          } else {
            // 明天以及后面的时间从0时开始
            st = '00:00:00'
          }

          this.startTimeRange = st + ' - 23:59:59'
          // 例如：如果今天此刻时间为15:27:00 则选择时间范围为： 15:27:00 - 23:59:59
          // 否则为：00:00:00 - 23:59:59
        }
      }
    }
  },

  computed: {
    ...mapGetters(['isAdmin', 'isAdmin', 'getOptions', 'getWayName'])
  },
  methods: {
    // 上传附件
    async upload (item) {
      if (this.isUploading) return
      // console.log('file', item)
      this.fileList = []
      var formData = new FormData()
      formData.append('file', item.file)
      // console.log('formData', formData)
      this.isUploading = true
      const res = await uploadVersionFile(formData)
      if (res.data && res.data.length > 0) {
        // this.importForm.remarks = res.data[0].FilePath
        this.fileList = [{
          name: res.data[0].FileName,
          url: res.data[0].FilePath
        }]
        this.batchAddForm.attachment_address = res.data[0].FilePath
      }
      this.isUploading = false
    },
    // 保存
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.batchAddFormRef.validate((valid) => {
        // 成功
        if (valid) {
          if (this.id) {
            this.fnUpdateVersionManagement()
          } else {
            this.fnAddVersionManagement()
          }
        } else {
          return false
        }
      })
    },
    // 新增版本管理
    async fnAddVersionManagement () {
      const res = await addVersionManagement(this.batchAddForm)
      if (res && res.Code === 200) return this.$emit('closeDialog', false)
    },
    // 编辑版本管理
    async fnUpdateVersionManagement () {
      const obj = this.batchAddForm
      obj._id = this.id
      const res = await updateVersionManagement(obj)
      if (res && res.Code === 200) return this.$emit('closeDialog', false)
    },
    // 根据id获取详情
    async fnGetVersionManagementDetail () {
      const res = await getVersionManagementDetail({ _id: this.id })
      if (res && res.Code === 200) {
        this.batchAddForm.attachment_address = res.Data.attachment_address
        this.batchAddForm.system_type = res.Data.system_type
        this.batchAddForm.version_desc = res.Data.version_desc
        this.batchAddForm.version_release = res.Data.version_release
        this.batchAddForm.phone_client = res.Data.phone_client
        this.batchAddForm.push_time = new Date(Date.parse(res.Data.push_time))
        this.batchAddForm.update_mode = res.Data.update_mode
        // this.batchAddForm.is_open = res.Data.is_open
      }
    },
    // 设置默认时间
    setdefaultDate () {
      this.defaultDate = this.$moment().add(1, 'm').format('HH:mm:ss')
      this.$nextTick(() => {
        document.getElementsByClassName('el-button--text')[0].setAttribute('style', 'display:none') // 隐藏此刻按钮
      })
    },
    // 筛选客户端
    filterPhoneClient () {
      const PhoneClientList = this.getOptions('GroupPhoneClient')
      this.filterAppClient = PhoneClientList.filter(item => {
        return item.value === 4 || item.value === 5
      })
    }
  },
  created () { },
  mounted () {
    // 默认时间一分钟后
    this.defaultDate = this.$moment().add(1, 'm').format('HH:mm:ss')
    if (this.id) {
      this.fnGetVersionManagementDetail()
    }
    // this.batchAddForm.push_time = this.$moment().format('YYYY-MM-DD')
    this.filterPhoneClient()
  }
}
</script>

<style scoped lang="less">
.form_box {
  overflow-y: auto;
  overflow-x: hidden;

  /deep/ .el-input {
    width: 100%;
  }

  /deep/ .el-select {
    width: 100%;
  }

  /deep/ .el-textarea {
    width: 100%;
  }

}

.box-card {
  position: relative;
  // padding-bottom: 30px;
  padding: 0 30px 30px 0;
  box-sizing: border-box;

  .checkButton {
    position: absolute;
    right: 0px;
  }

  .checkButton2 {
    position: absolute;
    right: 100px;
  }
}

// .el-time-spinner__wrapper {
//     width: 100% !important;
//   }
//   .el-scrollbar:nth-of-type(2) {
//     display: none !important;
//   }
</style>
