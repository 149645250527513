<!-- 版本管理 -->
<template>
  <div class="box-card" style="text-align: left">
    <div class='content'>
      <!-- 查询表单 -->
      <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm"
        @submit.native.prevent>
        <el-form-item label="客户端：">
          <el-select popper-class="my-select" filterable  v-model="queryForm.phone_client" placeholder="请选客户端"
           @change="onSubmit()" clearable>
            <el-option v-for="item in filterAppClient" :key="item.value" :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本说明：">
          <el-input placeholder="请输入版本说明" v-model="queryForm.version_desc"
            @input="(e) => (queryForm.version_desc = validSpace(e))" @keyup.enter.native="onSubmit"></el-input>
        </el-form-item>
        <el-form-item label="更新方式：" >
          <el-select popper-class="my-select" filterable  v-model="queryForm.update_mode" placeholder="请选更新方式" @change="onSubmit()" clearable>
            <el-option label="强制更新" :value=2></el-option>
            <el-option label="提示更新" :value=1></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <lbButton icon="chaxun" :fill="true" @click=" onSubmit()">查询</lbButton>
        </el-form-item>
      </el-form>
      <le-card title="版本管理">
        <template slot="heard-right">
          <div class="right">
            <lbButton type="err" icon="shanchu1" @click="deleteVersion()"  v-if="isShowBtn('99c13ef30ee2cf2822c00be8')">删除</lbButton>
            <lbButton icon="xinzeng" @click="addVersion()"  v-if="isShowBtn('99c13ef30ee2cf2812c00be8')">新增</lbButton>
          </div>
        </template>
        <!-- 主要内容 -->
        <div>
          <el-table class="tablestyle" ref="multipleTable" :data="tableData" align="center" header-align="center"   @selection-change="handleSelectionChange"
          tooltip-effect="dark" border stripe>
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column prop="parking_name" label="客户端" width="130">
            <template slot-scope="scope">
              {{ getWayName('GroupPhoneClient',scope.row.phone_client) }}
            </template>
          </el-table-column>
          <el-table-column prop="system_type" label="系统">
            <template slot-scope="scope">
              {{ scope.row.system_type === 1?'Android':'iOS' }}
            </template>
          </el-table-column>
          <el-table-column prop="version_release" label="版本号"></el-table-column>
          <el-table-column prop="version_desc" label="版本说明" width="200"></el-table-column>

          <el-table-column prop="push_time" label="推送时间" width="150">
            <template slot-scope="scope">
              {{ $moment(scope.row.push_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="update_mode" label="更新方式">
            <template slot-scope="scope">
              {{ scope.row.update_mode ===1?'提示更新':'强制更新' }}
            </template>
          </el-table-column>
          <el-table-column prop="is_open" label="状态" v-if="isShowBtn('99c13ef30ee4cf2852c00be8')">
            <template slot-scope="scope">
              <lebo-switch :status="!scope.row.is_open" :turnOn="'开启'" :turnOff="'关闭'"
                :switchType="scope.row.is_open" @switchChange="changeStatus(scope.row)"></lebo-switch>
            </template>
          </el-table-column>
          <el-table-column  label="更新数量">
            <template slot-scope="scope">
              {{  scope.row.device_update_count}}
            </template>
          </el-table-column>
          <el-table-column prop="abnormal_desc" label="创建时间" width="150">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <lbButton type="warning" icon="bianji" hint="编辑" @click="addVersion(scope.row._id)" v-if="isShowBtn('99c13ef30ee2cf2832c00be8')"></lbButton>
              <lbButton type="info" icon="erweima" hint="二维码" @click="showCode(scope.row)" v-if="isShowBtn('99c13ef30ee4cf2952c00be8')"></lbButton>
              <lbButton type="succes" icon="xiazai" hint="下载" @click="downLoad(scope.row)" v-if="isShowBtn('99c13ef30ee4cf2822c00be8')"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
        </div>
      </le-card>
    </div>
      <!-- 新增编辑版本管理弹框 -->
      <lebo-dialog :title="dialogVersionManagementId?'编辑版本管理':'新增版本管理'" :isShow="isShowAddVersion" width="50%" @close="isShowAddVersion=false" footerSlot>
     <addVersionManagement v-if="isShowAddVersion" :id="dialogVersionManagementId" @closeDialog="closeVersionManagement"></addVersionManagement>
    </lebo-dialog>
      <!-- apk下载二维码 -->
      <lebo-dialog append-to-body width="20%" title="下载安装包" :isShow="showQrCode" @close="showQrCode=false" footerSlot closeOnClickModal>
      <div class="content_box">
        <div class="qr_content">
          <vue-qr ref="qrRef" id="qrcode" :text="downloadQrCodeUrl" :margin="10"></vue-qr>
          <el-button size="small" @click="downQr(2)">下载安装包二维码图片</el-button>
        </div>
      </div>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { getClientVersion, changeVersionManagementStatus, deleteVersionManagement } from '@/api/maintenanceCenter'
import addVersionManagement from '../components/addVersionManagement/index.vue'
export default {
  name: 'appVersionManagement',
  components: { addVersionManagement },
  props: {},
  data () {
    return {
      queryForm: {
        update_mode: null, // 更新方式 1=提示更新，2=强制更新
        phone_client: null, // 手机端 GroupPhoneClient
        version_desc: null, // 版本说明
        PageSize: 10,
        PageIndex: 1
      },
      tableData: [],
      total: 0,
      multipleSelection: [],
      isShowAddVersion: false,
      dialogVersionManagementId: '',
      showQrCode: false,
      downloadQrCodeUrl: '',
      filterAppClient: []// 筛选只有app的客户端
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['isAdmin', 'isAdmin', 'getOptions', 'getWayName'])

  },
  methods: {
    // 筛选环境
    getAppEnvironment (type) {
      let enviroment = ''
      switch (type) {
        case 1:
          enviroment = '开发环境'
          return enviroment
        case 2:
          enviroment = '测试环境'
          return enviroment
        case 3:
          enviroment = '正式环境'
          return enviroment
      }
    },
    // 获取客户端版本列表
    async fnGetClientVersion () {
      // const res = await getClientVersion(this.queryForm)
      const res = await getClientVersion({
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex,
        version_desc: this.queryForm.version_desc,

        phone_client: this.queryForm.phone_client ? this.queryForm.phone_client : 0,
        update_mode: this.queryForm.update_mode ? this.queryForm.update_mode : 0

      })
      // console.log('res', res)
      this.tableData =
        res && res.Code === 200 && res.Data ? res.Data.DataList : []
      this.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetClientVersion()
    },
    // 修改推送状态
    async changeStatus (row) {
      console.log('row', row)
      const obj = {}
      obj._id = row._id
      obj.is_open = !row.is_open
      obj.phone_client = row.phone_client
      obj.system_type = row.system_type
      await changeVersionManagementStatus(obj)
      this.fnGetClientVersion()
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetClientVersion()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      //  // console.log(`当前页: ${val}`);
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetClientVersion()
    },
    // 关闭弹框
    closeVersionManagement () {
      this.isShowAddVersion = false
      this.fnGetClientVersion()
    },
    // 新增编辑版本
    addVersion (id) {
      this.isShowAddVersion = true
      this.dialogVersionManagementId = id
    },
    // 下载版本
    downLoad (row) {
      var a = document.createElement('a')
      a.style.display = 'none'
      a.download = this.getWayName('GroupPhoneClient', row.phone_client)
      a.href = row.attachment_address
      document.body.appendChild(a)
      a.click()
    },
    // 删除版本
    deleteVersion () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的版本, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            var obj = []
            var arr = []
            for (var i = 0; i < this.multipleSelection.length; i++) {
              arr.push(this.multipleSelection[i]._id)
              if (arr.length === this.multipleSelection.length) {
                obj._id = arr.join(',')
                this.fndelCoupon(arr)
              }
            }
          })
          .catch(() => {
            this.$msg.info('已取消删除')
          })
      } else {
        this.$msg.warning('请选择要删除的版本！')
      }
    },
    async fndelCoupon (obj) {
      const res = await deleteVersionManagement(obj)
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetClientVersion()
    },
    // 二维码弹框
    showCode (row) {
      console.log('row', row)
      this.showQrCode = true
      const apkUrl = row.attachment_address
      const urlList = row.attachment_address.split('/')
      const apkList = row.attachment_address.split('//')
      console.log('a', urlList)
      console.log('b', apkList)
      //  应用系统 1：安卓 2：ios 3:全系统展示
      const systemType = row.system_type
      // phone_client 4:路内收费 5：元点
      const apkType = row.phone_client
      // this.downloadQrCodeUrl = row.attachment_address
      //  "/download-apk/:systemtype/:apktype/:apkurl"
      this.downloadQrCodeUrl = urlList[0] + '//' + urlList[2] + '/payPages/download-apk' + '?systemtype=' + systemType + '&apktype=' + apkType + '&apkurl=' + apkList[1]
      console.log('erweima ', this.downloadQrCodeUrl)
    },
    // 下载二维码图片
    downQr () {
      var name = new Date().getTime()
      var a = document.createElement('a')
      a.style.display = 'none'
      a.download = name
      a.href = this.$refs.qrRef.$el.src
      document.body.appendChild(a)
      a.click()
    },
    // 筛选客户端
    filterPhoneClient () {
      const PhoneClientList = this.getOptions('GroupPhoneClient')
      this.filterAppClient = PhoneClientList.filter(item => {
        return item.value === 4 || item.value === 5
      })
    }
  },
  created () { },
  mounted () {
    this.fnGetClientVersion()
    this.filterPhoneClient()
  }
}
</script>

<style scoped lang="less">
.content {
  margin-top: 20px;
}
.content_box {
  display: flex;
  align-items: center;
  justify-content: center;
  .qr_content {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img {
      width: 100%;
    }
  }
}
</style>
